import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Anchor } from "../Anchor/Anchor"

interface DynamicImageProps {
  fileName: string
  alt: string
  isAnchorActive?: boolean
}

export const DynamicImage: React.FC<DynamicImageProps> = ({
  fileName,
  alt,
  isAnchorActive = false,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const image = data.images.edges.find((edge) => {
          return edge?.node?.relativePath?.includes(fileName)
        })
        if (!image) {
          return null
        }

        const imageJsx = (
          <Img alt={alt} fluid={image.node.childImageSharp.fluid} />
        )

        return isAnchorActive ? (
          <Anchor href={image.node.childImageSharp.fluid.src} target="_blank">
            {imageJsx}
          </Anchor>
        ) : (
          imageJsx
        )
      }}
    />
  )
}
