import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { Flex } from "../components/atoms/Flex/Flex"
import { Text } from "../components/atoms/Typography/Typography"
import { TopBar } from "../components/molecules/TopBar/TopBar"
import { Footer } from "../components/molecules/Footer/Footer"
import { blogPosts } from "../dataSources/blogPosts"
import { DynamicImage } from "../components/atoms/DynamicImage/DynamicImage"
import { Anchor } from "../components/atoms/Anchor/Anchor"
import { BlogTitle } from "../components/atoms/BlogTitle/BlogTitle"
import { Gap } from "../components/atoms/Gap/Gap"

const Container = styled(Flex)`
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 620px;
  margin: auto;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 2vh;
    box-sizing: border-box;
  }
`

const List = styled.div`
  max-width: 1100px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;

  @media screen and (max-width: 767px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 528px) {
    grid-template-columns: repeat(1, 1fr);
  }

  margin-bottom: 64px;
`
const ListItem = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: all 0.15s linear;

  :hover {
    transform: translateY(-2px);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  }
`

const Image = styled.div`
  width: 100%;
  min-height: 100px;
  object-fit: cover;

  > div {
    min-height: 100px;
  }
`

const ItemTitle = styled.div`
  text-align: center;
  padding: 16px 8px;
`

const StyledText = styled(Text)`
  line-height: 22px;
  text-decoration: none !important;
  border: none;
`

const Blog = () => {
  return (
    <Layout>
      <Container>
        <TopBar />
        <Content>
        <Gap height={48} />
          <BlogTitle emoji="✍️" title="Blog posts" />
          <Gap height={24} />
          <List>
            {blogPosts.map((post) => (
              <ListItem>
                <Anchor href={post.url}>
                  <Image>
                    <DynamicImage fileName={post.imageUrl} alt={post.id} />
                  </Image>
                  <ItemTitle>
                    <StyledText>{post.title}</StyledText>
                  </ItemTitle>
                </Anchor>
              </ListItem>
            ))}
          </List>
        </Content>
        <Footer />
      </Container>
    </Layout>
  )
}

export default Blog
