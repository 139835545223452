type Paragraph = {
  title: string
  content: string
  coverPictureUrl?: string
}

export type BlogPost = {
  id: string
  url: string
  title: string
  emoji: string
  imageUrl: string
  createdAt: string
  paragraphs: Paragraph[]
}

const websiteUrl = "https://lucarestagno.com"

const HowWeBuiltIterspace: BlogPost = {
  id: "how-we-built-iterspace",
  url: `${websiteUrl}/blog/how-we-built-iterspace`,
  title: "How we built iterspace",
  emoji: "📦",
  imageUrl: "how-we-built-iterspace.jpeg",
  createdAt: "1 Aug, 2021",
  paragraphs: [
    {
      title: "",
      content: `
I started [iterpace](https://iterspace.com) with [Marco Zani](https://marco-zani.webflow.io/) 
in the summer of 2018. The plan was to build a tool that would let people share clear feedback 
with the right context. A bit like Loom, but you get advanced commenting instruments and threads.

Visual feedback is not an entirely novel idea, of course, but the existing tools were all screenshot-based.
So we set out to build a video-based product.
      `,
    },
    {
      title: "### Architecture",
      coverPictureUrl: "iterspace_architecture.png",
      content: `
iterspace is a so-called single-page web app (SPA).
Most of the logic is implemented in the client-side that runs in 
the browser and syncs updates to the backend view HTTP requests.
The cloud notifies the app about updates made by other users via WebSocket.

All of the application's data are stored in a DynamoDB database in the Amazon Web Services (AWS) cloud.
This gives us a lot of room to scale and keep the price contained.

All the services and data stores run in Amazon Web Services.
We have AWS Amplify to host the client application on the public Internet and 
put it on edge through the CDN. The CDN routes the requests to either AWS S3 for static resources, 
such as images or videos.
      `,
    },
    {
      title: "### JavaScript and Node.js",
      content: `
One of the most far-reaching tech decisions a startup needs to make is what programming language(s) to use. 

For iterspace, we chose JavaScript and its server-side engine Node.js.
Having one language across the whole stack is a significant speed boost, 
as it reduces the mental overhead of switching between parts of the stack.

The criticism that JavaScript gets is the lack of static typing.
But by using the JavaScript superset TypeScript, we were able to add typings on both
the front end and the back end.
      `,
    },
    {
      title: "### Frontend",
      content: `
For iterspace, we picked React as the UI library of reference.

React is a small library focused on doing one thing — rendering UI.
It does not dictate how to manage the state in a larger application.
Therefore we picked Redux for the application state management and Redux Saga 
for the side effects handling.

We came from working experiences where we built a design system with a brand identity injected into it.
For a startup, speed is critical. Therefore we evaluated several component libraries.
The prerequisites were React, styled-component, and components written with the functional approach.
We picked Zendesk Garden from Zendesk as the base React components library, and I must say that the decision was worth it.

For startups, development speed is critical. So we started using Storybook to build the components in isolation. Basically, when you need to create a new component, you make a story first. A story is a page that renders one or more components.

As you write the code in the new component file, you see it updated almost immediately in the browser. 

It helps you a lot in thinking about Presentational and Container Components.
        `,
    },
    {
      title: "### Backend",
      content: `
The backend service for iterspace is a lightweight layer between the client and the data store. It is a GraphQL API feed by Lambda Functions (Serverless).

There is no CPU-intensive processing. Therefore, there was no need for always running servers.

We used Lerna to manage a monorepo containing all the Lambda Functions code in different packages. In addition, we created common packages to share the core model between the other Lambda Functions.`,
    },
    {
      title: "### Video",
      content: `
iterspace stores and serves screen recordings, and therefore we had to understand how to distribute video reliably.

Most of the recordings come from the browser (\`webm\` with the \`h264\` video codec), but a video can be uploaded too, and therefore we had to support multiple formats (\`mov\`, \`mp4\`).

We use AWS Elemental MediaConvert to encode the video to \`webm\` and \`mp4\`. We had to encode both formats because the \`webm\` playback works on most browsers (Chrome, Edge, Firefox) but not in Safari.`,
    },
    {
      title: "### That's all!",
      content: `
Building a product is a highly challenging activity. It requires many decisions to be taken rapidly. Nevertheless, we try to deliver value to the customers as soon as possible, even with rough features.

Then we gather feedback, adjust the feature based on that, and iterate the process.

Moreover, we use iterspace while working on iterspace! And we are the first customers of our own product.

Hope you enjoyed 🙌`,
    },
  ],
}

export const blogPosts: BlogPost[] = [HowWeBuiltIterspace]
